html, body {
  background-color: #f1f2f3;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#logo {
  height: 68px;
  margin-right: 1em;
  cursor: pointer;
}

#userEmail {
  opacity: 1;
  color: white;
  background-color: #33a952;
  margin-top: -8px;
  margin-bottom: 5px
}

#userEmail svg {
  margin-right: 5px;
}

.main {
  margin: 1em;
}

.sheets {
  color: #33a952;
  font-weight: bold;
  text-decoration: none;
}

h1 {
  font-size: 1.75rem !important;
}

h3.space {
  margin: 2em 0 .5em 0 !important;
}

.MuiFormControl-root {
  margin: 0 !important;
}

.inline-button {
  margin: 10px 0 0 10px !important;
  width: 95px;
}

.addNewFieldContainer {
  margin: 1em !important;
}